// File focused on adding Angular Material workround variables.

/* SWITCH / SLIDE TOGGLE */
.mat-mdc-slide-toggle {
	// Default variables
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-shape: 9999px;
  --mdc-switch-selected-icon-size: 16px;
  --mdc-switch-track-height: 32px;
  --mdc-switch-track-shape: 9999px;
  --mdc-switch-track-width: 52px;
  --mdc-switch-unselected-icon-size: 16px;
  --mdc-switch-state-layer-size: 40px;
  --mat-switch-disabled-selected-handle-opacity: 1;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 16px;
  --mat-switch-selected-handle-size: 24px;
  --mat-switch-pressed-handle-size: 28px;
  --mdc-switch-handle-width: 52px;
  --mat-switch-with-icon-handle-size: 24px;
  --mat-switch-selected-handle-horizontal-margin: 0 24px;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0 24px;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0 22px;
  --mat-switch-unselected-handle-horizontal-margin: 0 8px;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0 4px;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0 2px;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 0;
  --mat-switch-visible-track-transition: opacity 75ms;
  --mat-switch-hidden-track-transition: opacity 75ms;
  --mat-switch-track-outline-width: 2px;
  --mat-switch-selected-track-outline-width: 0;
}
// Slide Toggle variants
.mat-mdc-slide-toggle.mat-primary {
	--mdc-switch-selected-track-color: #dadce0;
	--mdc-switch-selected-pressed-track-color: #dadce0;
	--mdc-switch-selected-handle-color: #3c4043;
	--mdc-switch-selected-pressed-handle-color: #3c4043;
	--mdc-switch-selected-hover-handle-color: #202124;
	--mdc-switch-selected-focus-handle-color: #202124;
	--mdc-switch-selected-icon-color: #dadce0;
	--mdc-switch-selected-hover-icon-color: #dadce0;
	--mdc-switch-selected-focus-icon-color: #dadce0;
	--mdc-switch-selected-pressed-icon-color: #dadce0;

	--mdc-switch-unselected-track-color: #5f6368;
	--mdc-switch-unselected-handle-color: #dadce0;
	--mdc-switch-unselected-hover-handle-color: #9aa0a6;
	--mdc-switch-unselected-focus-handle-color: #9aa0a6;
	--mdc-switch-unselected-icon-color: #202124;
	--mdc-switch-unselected-hover-icon-color: #202124;
	--mdc-switch-unselected-focus-icon-color: #202124;
	--mdc-switch-unselected-pressed-icon-color: #202124;
}

.mat-mdc-slide-toggle.mat-info {
	--mdc-switch-selected-track-color: #BBDEFB;
	--mdc-switch-selected-hover-track-color: #E3F2FD;
	--mdc-switch-selected-pressed-track-color: #BBDEFB;
	--mdc-switch-selected-focus-track-color: #BBDEFB;
	--mdc-switch-selected-handle-color: #1565C0;
	--mdc-switch-selected-pressed-handle-color: #1565C0;
	--mdc-switch-selected-hover-handle-color: #0D47A1;
	--mdc-switch-selected-focus-handle-color: #0D47A1;
	--mdc-switch-selected-icon-color: #BBDEFB;
	--mdc-switch-selected-hover-icon-color: #BBDEFB;
	--mdc-switch-selected-focus-icon-color: #BBDEFB;
	--mdc-switch-selected-pressed-icon-color: #BBDEFB;

	--mdc-switch-unselected-track-color: #42A5F5;
	--mdc-switch-unselected-hover-track-color: #1565C0;
	--mdc-switch-unselected-pressed-track-color: #42A5F5;
	--mdc-switch-unselected-focus-track-color: #42A5F5;
	--mdc-switch-unselected-handle-color: #E3F2FD;
	--mdc-switch-unselected-hover-handle-color: #90CAF9;
	--mdc-switch-unselected-focus-handle-color: #BBDEFB;
	--mdc-switch-unselected-icon-color: #0D47A1;
	--mdc-switch-unselected-hover-icon-color: #0D47A1;
	--mdc-switch-unselected-focus-icon-color: #0D47A1;
	--mdc-switch-unselected-pressed-icon-color: #0D47A1;
}
