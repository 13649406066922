//fix table head on mobile

@use 'sass:map';
@use '@angular/material' as mat;
@use './shared/variables' as vars;
@use './shared/material_variables';
@import './shared/mixins';
@import './shared/shared';

@import 'https://fonts.googleapis.com/css2?family=Material+Icons+Extended&family=Material+Icons+Outlined&family=Google+Sans:wght@400;500;600;700&family=Roboto:wght@400;500;600;700';

// Core styles
$typography: mat.define-typography-config();
@include mat.all-component-typographies($typography);
@include mat.core();

// Dark Theme
$primary: mat.define-palette(vars.$gmat-gray);
$accent: mat.define-palette(mat.$red-palette, 800);
$info: mat.define-palette(mat.$blue-palette, 800);
$warn: mat.define-palette(mat.$orange-palette);
// TODO: Provide this application's typography in the
// theme configuration instead of providing null.
$dark-theme: mat.define-dark-theme((color: (primary: $primary,
        accent: $accent,
        warn: $warn,
				info: $info,
      ),
      density: 0,
      typography: null,
    ));

// Override theme background
$dark-theme: map.set($dark-theme,
    'color',
    'background',
    'background',
    vars.$gmat-gray-900);

// Theme styles
@include mat.all-component-themes($dark-theme);

// Global styles
.mam-html,
.mam-html body {
  font-family: Roboto, sans-serif;
  height: 100%;
  width: 100%;
  margin: 0;
  overscroll-behavior: none;

  /* scrollbar customization */
  ::-webkit-scrollbar {
    width: 5px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: vars.$mam-white-8;
    border-radius: 7px;

    &:hover {
      background: vars.$mam-white-30;
    }
  }

  .build-info {
    position: fixed;
    font-size: 8px;
    top: 2px;
    right: 2px;
    z-index: 50;
    color: vars.$gmat-gray-700;
  }
}

// Styles for page view component content container.
.centered-content {
  max-width: vars.$mam-view-max-width;
  margin: auto;
  padding: 0 16px 20px 12px;

  @include breakpoint-md-max() {
    padding: 0 16px 20px 16px;
  }

// Styles for mobile Vertical view component content container.
//  @include breakpoint-lg-max {
//    padding: 0 10px 10px 10px;
//  }
}

// Ensure that hidden elements do not override their display.
[hidden] {
  // stylelint-disable-next-line declaration-no-important --
  // This is a global style rule used to hide an element and it needs to
  // overpower all other selectors.
  display: none !important;
}

// Generalized font smoothing, only works on OSX / macOS
// https://developer.mozilla.org/en-US/docs/Web/CSS/font-smooth
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Material default overrides
mam-app {

  .mat-toolbar,
  .mat-toolbar h1 {
    font: 500 16px/24px Roboto, Helvetica Neue, sans-serif;
    letter-spacing: .2px;
    margin: 0;
  }

  .mat-mdc-card {
    display: block;
    overflow: hidden;
    position: relative;
    padding: 0;
    background: none;
    border: none;
    border-radius: 4px;
    height: 100%;
    width: 100%;

    &:focus {
      // TODO: Support keyboard navigation.
      outline: none;
    }
  }


  .mat-mdc-button,
  .mat-mdc-outlined-button {
    font-family: 'Google Sans', sans-serif;
    letter-spacing: 0;
  }

  .mat-mdc-icon-button:hover,
  .mat-mdc-button-persistent-ripple::before,
  .mat-mdc-button-persistent-ripple::before {
    background-color: transparent;
  }

  .mam-border {
    &--bottom {
      border-bottom: 1px solid vars.$gmat-gray-800;
    }
  }

  .mam-elevation-1 {
    @include elevation(0px 2px 3px rgba(0, 0, 0, 0.7));
  }

  .mam-elevation-2 {
    @include elevation(0px 4px 12px rgba(18, 19, 21, 0.7));
  }

  .mat-mdc-fab {
    &.mat-new-action {
      background-color: vars.$gmat-gray-900;
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.4), 0 -4px 11px #1f2125, inset 0 1px 1px #2e3236;
    }

    &.mam-fab-small {
      width: 40px;
      height: 40px;
    }

    &.mam-fab-medium {
      width: 48px;
      height: 48px;
    }

    &.mam-fab-square {
      border-radius: 8px;

      .mdc-fab__ripple {
        border-radius: 8px;
      }

      @include breakpoint-md-max {
        border-radius: var(--mdc-fab-container-shape) !important;
      }
    }
  }

  .text-ellipsis {
    @include ellipsis;
  }

  /*
    Apply this class "mam-datepicker-outline" to a form field containing date
    pickers to make it look like the date range facets
  */
  .mat-mdc-form-field-type-mat-date-range-input[appearance='fill'].mam-datepicker-outline {
    height: 40px;
    border: 1px solid vars.$gmat-gray-700;
    border-radius: 6px;

    &.mat-mdc-form-field-invalid:not(.mat-focused) {
      border-color: vars.$mam-error-orange;
    }

    .mat-mdc-button-touch-target {
      height: inherit;
      width: inherit;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-mdc-form-field-flex {
      background: none;
      padding: 0;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-mdc-floating-label {
      top: -0.92em;
      font-size: 14px;
      color: vars.$gmat-gray-300;
      letter-spacing: normal;
    }

    $font-size: 1rem;

    .mat-mdc-date-range-input {
      font-size: $font-size;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-mdc-form-field-wrapper,
    .mat-mdc-form-field-infix {
      padding: 0;
    }

    .mdc-icon-button{
      padding: 8px 0;
      height: 40px;
      width: 40px;
    }

    .datapicker__input--wrapper{
      position: relative;
      top: 8px;
    }

    .mdc-floating-label {
      top: 20px;
    }


    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      background: none;
    }

    .mat-mdc-form-field-focus-overlay {
      background: none;
    }

    .mdc-text-field {
      border-color: none;
      padding: 0 !important;
    }

    .mdc-line-ripple {
      visibility: hidden;
    }

    /*
      inputs need a set width to function properly, and these inputs scale off the width of this element
      since inputs use rem for font-size, to ensure there is enough space the width of the input should also scale by rem
      13 just happened to be a good magic number for this
    */
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-mdc-form-field-infix {
      width: calc(12 * #{$font-size});
      min-height: 40px;
    }
  }
}

.mat-mdc-icon-button.mat-mdc-button-base {
    --mdc-icon-button-state-layer-size: 40px;
    width: var(--mdc-icon-button-state-layer-size);
    height: var(--mdc-icon-button-state-layer-size);
    padding: 8px;
}

.cdk-overlay-container {

  // Reset toolTip margin to 8px instead of 24px.
  // Default 24px margin sets tooltips very far from the target.
  /* TODO(mdc-migration): The following rule targets internal classes of tooltip that may no longer apply for the MDC version.*/
  .mat-tooltip-handset {
    margin: 8px;
  }

  // Ignore mouse events over tooltips.
  .mat-mdc-tooltip-panel {
    pointer-events: none;
  }

  .mat-mdc-menu-panel {
    background: vars.$gmat-gray-800;
    padding-top: 12px;
    margin-top: 10px;

    &.menu-offset-down {
      margin-top: 8px;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of menu that may no longer apply for the MDC version.*/
    .mat-menu-title {
      color: vars.$gmat-gray-500;
      font-size: 12px;
      padding: 0 12px 12px;
    }

    span:first-child {
      font-size: 14px;
    }



    .mat-mdc-menu-item {
      text-align: left;
      min-height: 36px;
      padding-right: 28px;

      // When an icon prefixes text in a menu item, adjusts the item
      // padding so that it is not too far from the icon.
      mat-icon~span {
        padding-left: 0;
      }
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version.*/
  //DONE
  .mat-mdc-autocomplete-panel {
    background: vars.$mam-black;
    border-bottom-left-radius: 26px;
    border-bottom-right-radius: 26px;
    max-height: 50vh;
    border-top: 1px solid vars.$mam-white-38;

    .mat-mdc-option {
      /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
      //DONE
      .mdc-list-item__primary-text {
        flex: 1;
        display: flex;
        align-items: center;
        width: 100%;

        > span {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          // b/168040967: prevent collision with mat-option click detection.
          pointer-events: none;

          +.mat-mdc-icon-button .mat-mdc-icon {
            margin-right: 0;
          }
        }

        span.bold {
          font-weight: bold;
        }

        span.light {
          color: vars.$mam-white-87;
        }

        .mat-icon {
          color: vars.$mam-white-60;
        }
      }

      &.mat-mdc-selected:not(.mat-mdc-active):not(:hover) {
        background: unset;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
      &.mat-mdc-active,
      &:focus:not(.mat-mdc-option-disabled),
      &:hover:not(.mat-mdc-option-disabled) {
        background: vars.$mam-white-8;
      }
    }
  }

  .mat-mdc-tooltip.close-from-top {
    margin-top: 2px;
  }

  .mat-mdc-datepicker-content {
    background-color: vars.$gmat-gray-800;

    .mat-mdc-raised-button.mat-primary {
      background-color: vars.$gmat-gray-700;
    }
  }
}

.cdk-overlay-pane {
  justify-content: center;
}

// Dialogs overrides
.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.74);
}

/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
.gmat-dialog-panel .mat-mdc-dialog-container {
  box-shadow: none;
}



.mdc-dialog__container {
  background:#202124;
  border-radius: 8px;
  min-width: 350px;

  .mat-mdc-dialog-title {
    color: white;
    padding: 0;
    margin-bottom: 8px;
    display: flex;
    letter-spacing: normal;

    &~h2 {
      margin-top: -12px;
    }

    @include breakpoint-sm-max-height {
      margin: 0;
    }
  }

  h2 {
    @include ellipsis;
    font-size: 14px;
    font-weight: normal;
    color: vars.$gmat-gray-400;
    margin-bottom: 20px;

    @include breakpoint-sm-max-height {
      margin-bottom: 0;
    }

    em {
      font-style: normal;
      font-weight: 500;
    }
  }

  p {
    font-size: 13px;
    letter-spacing: 0.4px;
    color: white;
  }

  label {
    font-size: 12px;
    letter-spacing: 0.4px;
    color: vars.$mam-white-60;
  }

  // custom styles
  .mam-dialog {
    &_wrapper {
      margin-bottom: 24px !important;
    }

     &_content {
      &__text {
        font-size: 14px;
        line-height: 24px;
        color: vars.$mam-white-87;
        margin-block: 0;
      }
     }
  }
  // end Custom

  .mat-mdc-dialog-content {
    @include breakpoint-sm-max-height {
      max-height: unset;
    }

    &.mdc-dialog__content {
      padding: 0;
    }
  }

  .mat-mdc-form-field {
    width: 100%;
    background: rgba(0, 0, 0, 0.38);
    letter-spacing: normal;
    margin-bottom: 21px !important;

    .mat-mdc-form-field-flex {

      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border: none;
      }
    }

    .mat-mdc-text-field-wrapper{
      padding: 0 12px;
    }

    .mat-mdc-form-field-outline {
      border-radius: 4px;

      >div {
        border: 0;
      }
    }

    .mat-mdc-form-field-infix {
      min-height: 50px !important;
    }

    @include breakpoint-sm-max-height {
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-wrapper {
        padding: 0 0 5px 0;
        margin: 0;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-infix {
        border-top-width: 0;
        padding: 8px 0;
      }
    }
  }

  .mat-mdc-dialog-actions {
    justify-content: flex-end;
    padding:  0 !important;
    align-items: flex-end;

    mat-mdc-button[disabled], .mat-mdc-button.mat-mdc-button-disabled {
      color: #ffffff4d;
    }

    button {
      text-transform: uppercase;
      letter-spacing: normal;

      &:not([disabled]) {
        color: vars.$mam-white-60;

        &.mat-primary {
          color: white;
        }

        &.mat-danger {
          color: vars.$gmat-red-500;
        }
      }
    }
  }

  .mat-mdc-dialog-surface.mdc-dialog__surface {
    background: #202124;
    padding: 24px;
  }
}

// Snackbars overrides
.mat-mdc-snack-bar-container {
  .mdc-snackbar__surface {
    background: vars.$gmat-gray-800 !important;
    * > * > .mat-mdc-simple-snack-bar > .mat-mdc-snack-bar-label  {
      color: vars.$mam-white-87 !important;
    }
  }
  // Prevent overflow of long text in snackbars.
  .mat-mdc-simple-snack-bar>span {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mat-mdc-snack-bar-action {
    color: vars.$gmat-red-400 !important;
  }

  &.error > .mdc-snackbar__surface {
    background: vars.$gmat-red-700 !important;
    * > * > .mat-mdc-simple-snack-bar > .mat-mdc-snack-bar-label  {
      color: white !important;
    }

    button {
      color: white !important;
    }
  }
}

// Material input styles
input.mat-mdc-input-element {
  font-size: 14px !important;
  letter-spacing: 0 !important;
  line-height: normal !important;
}

// Search bar
@media(max-width:420px){
  .actions-search-bar-container {
    .search-bar-container {
      .search-bar {
        .mat-mdc-form-field-input-control {
          font-size: 3.75vw !important;
        }
      }
    }
  }
}



// Material paginator styles
.mat-mdc-paginator {
  background: none;
  background-color: transparent !important;

  .mat-mdc-paginator-range-actions {
    color: vars.$mam-white-70;
  }

  .mat-mdc-paginator-range-label {
    font-size: 12px;
    margin: 0 24px;

    @media (max-width: 700px) {
      margin: 0 12px;
    }

    @media (max-width: 600px) {
      font-size: 13px !important;
      margin: 0 5px !important;
      padding-top: 1px;
    }
    @media (max-width: 420px) {
      min-width: 40px !important;
      font-size: 2.75vw !important;
      line-height: 1.2em;
    }
  }


// Material slide toggle
.mat-mdc-slide-toggle {
  .mdc-switch {
      width: 52px;
  }
  .mdc-label {
      width: max-content;
  }
}

  .mat-mdc-icon-button {
    @include breakpoint-md-max {
      width: 28px;
    }
  }



  button[disabled] {
    color: vars.$mam-white-30;
  }

}

.mat-paginator-icon {
  width: 30vh;
  margin-top: -0.5vh;
}

// Material slide toggle
.mat-mdc-slide-toggle {
  .mdc-switch {
      width: 52px;
  }

  .mdc-label {
      width: max-content;
  }
}

// Global classes
.invisible {
  visibility: hidden;
}

// Clip Selection Drag and drop
.row-container.cdk-drag-preview .mat-mdc-card:not([class*='gmat-elevation-z']) {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

// TODO: Consider removing this override.
// Angular Material icons default to overflow hidden. This override is necessary
// to avoid breaking layout or tests. See this pull request for the change:
// https://github.com/angular/components/pull/12429
mat-icon.mat-icon {
  overflow: visible;
}

mat-icon.overlay-domain-icon {
  margin-right: 10px;
}

.arrow.overlay-arrow {
  margin-bottom: 4px;
}

.mat-mdc-unelevated-button.mat-primary,
.mat-mdc-raised-button.mat-primary,
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  color: white;
}

.cdk-overlay-container .bulk-action-popup-center+.cdk-overlay-connected-position-bounding-box {
  align-items: center;
  max-width: 400px;
  width: 100%;

  @include breakpoint-lg-max {
    max-width: 360px;
  }

  @include breakpoint-md-max {
    width: unset;

  }
}

.add-pop-up+.cdk-overlay-connected-position-bounding-box {
  max-width: 190px;
  width: 100%;

  @media(min-width: vars.$medium) {
    max-width: 360px;
  }
}

 @include breakpoint-md-max {

  .mat-date-range-input-inner {
    bottom: 1px;
  }
}

.cdk-overlay-pane .mat-mdc-dialog-container {
  min-width: 550px;
  min-height: 190px;

  .mam-dialog--small {
    min-width: unset;
    width: 100%;
    max-width: 500px;
  }

}

@include breakpoint-md-max {
  .cdk-overlay-pane {

    .mat-mdc-dialog-container {
      min-width: 300px;
    }

    .mat-mdc-dialog-title {
      margin: 0 0 10px 0;
    }

    .mat-mdc-dialog-container h1.mat-mdc-dialog-title {
      font-size: 16px;
    }

    .mat-mdc-dialog-actions {
      min-height: 34px;
    }

    tr.mat-mdc-header-row {
      height: 30px;
    }
  }
}


@media (max-width: vars.$small) and (orientation: portrait),
(max-width: vars.$medium) and (orientation: landscape) {
  .mat-datepicker-popup {
    .mat-datepicker-content {
      .mat-calendar {
        height: 190px;

        .mat-calendar-header {
          padding: 0 5px 0 5px;

          .mat-calendar-controls {
            margin: 0;
          }
        }

        .mat-calendar-content {
          padding: 0 8px 2px 8px;
        }

        .mat-calendar-table-header th {
          padding: 0 0 5px 0;
        }
      }

      .mat-datepicker-actions {
        padding: 0 8px 3px 8px;
      }

      .mat-calendar-body-label,
      .mat-calendar-body-cell-container {
        padding-top: 3.5% !important;
        padding-bottom: 3.5% !important;
      }
    }
  }
}

.mat-datepicker-popup {
  @media (min-width: vars.$small) and (max-width: vars.$medium) and (orientation: portrait) {
    right: 85px !important;
  }

  @media (min-width: vars.$small) and (orientation: portrait) {
    right: 2px !important;
  }

  @media (max-width: vars.$medium) and (orientation: landscape) {
    top: 41.5px !important;
  }
}

.mat-date-range-input-inner {

  @media (max-width: vars.$web) and (orientation: portrait),
  (max-width: vars.$large) and (orientation: landscape) {
    bottom: 1px;
    font-size: 16px;
  }
}

button.asset-table-button {
  background: vars.$gmat-gray-800;
  padding: 0 8px;
  height: 28px;
  letter-spacing: 0 !important;
}

// if the user has a mouse, then we hide the material tooltip
@include mouse-pointer {
  .mobile-tooltip {
    display: none;
  }
}

.main__icon--size {
  width: 40px !important;
  padding: 8px !important;
  height: 40px !important;
}

// This class is used in SelectSchema component.
// It shouldn't be here, but as the component is within an overlay, adding it to its own class doesn't work.
.select-schema-panel-mat-select{
  padding: 0 !important;
  border-radius: 4px !important;

  .mat-pseudo-checkbox{
    display: none;
  }
}
.mat-mdc-option .mat-pseudo-checkbox{
  display: none;
}
// This class is used in MatPaginator component (popup options for "page size" selector).
// Popup is implemented within an overlay pane that is ouside of component.
// So the only way to force this style to work is to place it here.
.mat-mdc-select-panel[aria-labelledby*="mat-paginator"] {
  min-width: calc(100% + 32px);
}

// This class is used for all dropdowns in the app (paginator's "page size" selector,
// metadata fields, play description fields, etc.).
// Popup is implemented within an overlay pane that is ouside of component.
// So the only way to force this style to work is to place it here.
.mat-mdc-select-panel {
  padding: 0 !important;

  mat-option.mat-mdc-option {
    font-size: 12px;
    letter-spacing: 0;
    min-height: 36px;

    .mdc-list-item__primary-text {
      display: block;
      width: 100%;
    }

    &.metadata-item {
      font-size: 14px;
      letter-spacing: 0;
      min-height: 42px;
      font-family: Roboto, "Helvetica Neue", sans-serif;
    }
  }
}


.main__icon-arrow--size {
    margin-right: 0 !important;
    height: 24px !important;
    width: 24px !important;
    align-content: center !important;
    font-size: 24px !important;
}

.dialog__input--spacing {
  line-height: 18px !important;
}

.dialog__input-search--spacing {
  line-height: 17px !important;

  .mat-mdc-form-field-infix {
    padding: 0 !important;
  }
  input {
    padding: 16px 0 14px 0;
  }
}

.dialog__title--size {
  margin-bottom: 24px !important;
}

.previous.mdc-icon-button.mat-mdc-icon-button,
.next.mdc-icon-button.mat-mdc-icon-button {
  padding: 12px 0;
}


.dialog__input--size {
  width: 302px !important;

  @include breakpoint-md-max {
    width: 100% !important;
  }
}


.main__mat-paginator--border {
  .mat-mdc-form-field {
    .mat-mdc-text-field-wrapper {
      padding-left: 0 !important;
    }
    .mat-mdc-form-field-flex .mdc-notched-outline > * {
      border: none;
    }

    .mat-mdc-form-field-infix .mat-mdc-select .mat-mdc-select-trigger {
      border-bottom: 1px vars.$mam-white-70 solid !important;
      width: 56px;

      .mat-select-value-text {
        font-size: 12px !important;
        letter-spacing: 0 !important;
      }
    }
  }

  // Default width for the paginator's "page size" selector.
  .mat-mdc-paginator-page-size-select {
    width: 56px;
  }

  // Colors for the paginator's prev/next buttons (enabled VS disabled).
  .mat-mdc-paginator-icon {
    fill:  vars.$mam-white-70;
  }
  .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
    fill: vars.$mam-white-30;
  }

  // Paginator's "page size" label
  .mat-mdc-paginator-page-size-label {
    letter-spacing: 0 !important;
    color: vars.$mam-white-70;
  }

  // Paginator's "Range" label.
  .mat-mdc-paginator-range-label {
    letter-spacing: 0 !important;
    color: vars.$mam-white-70;
  }

  // Size for the paginator's prev/next buttons.
  .mat-mdc-paginator-navigation-first,
  .mat-mdc-paginator-navigation-last,
  .mat-mdc-paginator-navigation-previous,
  .mat-mdc-paginator-navigation-next {
    width: 40px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;

    @include breakpoint-md-max {
      width: 28px !important;
      padding-left: 2px !important;
      padding-right: 2px !important;
    }
  }

  // Color of the paginator's "page size" selector.
  .mat-mdc-select-value-text {
    color: white;
    font-size: 12px !important;
    letter-spacing: 0 !important;
  }
}


.mat-mdc-raised-button:not(:disabled) {
  color: white !important;
  background-color: #5f6368 !important;
}

button.mdc-button {
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

mat-form-field div.mat-mdc-form-field-infix {
  letter-spacing: normal !important;
}

.cdk-overlay-container .mat-datepicker-content {
  background-color: #3c4043;
}

.clipbins_autocomplete {
    height: 300px;
}


.mat-mdc-table {
  font-family: Roboto, Helvetica Neue, sans-serif;

  tbody {
    tr, td {
      height: 48px !important;
      min-height: 48px !important;
      letter-spacing: normal !important;
    }
  }
}

.w-100 {
  width: 100% !important;
}

// aligns option's check mark for the filterable columns
.mam-table-header-filter-menu mat-icon.checked {
  vertical-align: middle;
}

// aligns option's check mark for the filterable columns
.mam-table-header-filter-menu mat-icon.checked {
  vertical-align: middle;
}

// removes extra bottom margin for 'play description' dialog's metadata fields
.mdc-dialog__container mam-play-description-dialog mam-metadata-fields mat-form-field.mat-mdc-form-field {
  margin-bottom: 0 !important;
}

// aligns option's check mark for the filterable columns
.mam-table-header-filter-menu mat-icon.checked {
  vertical-align: middle;
}

// removes extra bottom margin for 'play description' dialog's metadata fields
.mdc-dialog__container mam-play-description-dialog mam-metadata-fields mat-form-field.mat-mdc-form-field {
  margin-bottom: 0 !important;
}

// Checkbox in the results table/list (not in the search-facet)
.mat-mdc-checkbox:not(.search-facet__checkbox) {
  .mdc-checkbox__background {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-left: 1px;
    margin-top: 1px;

    path.mdc-checkbox__checkmark-path {
      stroke: #202124;
      stroke-width: 1px;
      // tricky custom checkmark (works for desktop and android)
      d: path("M 2.06 12.78 L 8.458 18.51 L 21.99 4.67");
    }
  }

  .mdc-checkbox__mixedmark {
    width: 9px !important;
    height: 0.1px !important;
    border-radius: 25px !important;
    background-color: #202124 !important;
  }

  &.mat-primary {
    --mdc-checkbox-unselected-focus-state-layer-color: transparent;
    --mdc-checkbox-unselected-hover-state-layer-color: transparent;
    --mdc-checkbox-selected-focus-state-layer-color: transparent;
    --mdc-checkbox-selected-hover-state-layer-color: transparent;
    --mdc-checkbox-unselected-focus-icon-color: #bfc0c0 !important;
    --mdc-checkbox-unselected-hover-icon-color: #bfc0c0 !important;
    --mdc-checkbox-unselected-icon-color: #bfc0c0 !important;
    --mdc-checkbox-selected-focus-icon-color: #9aa0a6 !important;
    --mdc-checkbox-selected-hover-icon-color: #9aa0a6 !important;
    --mdc-checkbox-selected-icon-color: #9aa0a6 !important;

    // Border adjustments on the main checkbox element
    .mdc-checkbox__background {
      width: 16px;
      height: 16px;

      path.mdc-checkbox__checkmark-path {
        stroke: #313337; // Custom check color
        stroke-width: 3px;
      }
    }

    // Ripple effect for hover state
    &:hover .mdc-checkbox__ripple {
      background-color: #9aa0a6 !important;
      opacity: 0.06 !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
    }
  }

  // Disable transitions for search facets if needed
  &.search-facet__checkbox .mdc-checkbox {
    *, *::before {
      transition: none !important;
      animation: none !important;
    }
  }
}


// checkboxes common layout (at least works for facets options and search results list)
.mat-mdc-checkbox {
  .mdc-checkbox__background {
    path.mdc-checkbox__checkmark-path {
      stroke: #202124;
      stroke-width: 1px;
      // tricky custom checkmark (works for desktop and android)
      d: path("M 4.1,12.7 9,17.6 20.3,6.3");
    }
  }

  .mdc-checkbox__mixedmark {
    width: 9px !important;
    height: 0.1px !important;
    border-radius: 25px !important;
    background-color: #202124 !important;
  }

  &.mat-primary {
    --mdc-checkbox-unselected-focus-state-layer-color: transparent;
    --mdc-checkbox-unselected-hover-state-layer-color: transparent;
    --mdc-checkbox-selected-focus-state-layer-color: transparent;
    --mdc-checkbox-selected-hover-state-layer-color: transparent;
    --mdc-checkbox-unselected-focus-icon-color: #bfc0c0 !important;
    --mdc-checkbox-unselected-hover-icon-color: #bfc0c0 !important;
    --mdc-checkbox-unselected-icon-color: #bfc0c0 !important;
    --mdc-checkbox-selected-focus-icon-color: #9aa0a6 !important;
    --mdc-checkbox-selected-hover-icon-color: #9aa0a6 !important;
    --mdc-checkbox-selected-icon-color: #9aa0a6 !important;
    --mdc-checkbox-disabled-selected-checkmark-color: #313337 !important;
    --mdc-checkbox-disabled-unselected-checkmark-color: #313337 !important;

    --mdc-checkbox-selected-focus-state-layer-opacity: 0;
    --mdc-checkbox-unselected-focus-state-layer-opacity: 0;

    // Border adjustments on the main checkbox element
    .mdc-checkbox__background {
      width: 16px;
      height: 16px;

      path.mdc-checkbox__checkmark-path {
        stroke: #313337;
        stroke-width: 2px;
      }
    }

    // Ripple effect for hover state
    &:hover .mdc-checkbox__ripple {
      background-color: #9aa0a6 !important;
      opacity: 0.06 !important;
    }
  }

  // Disable transitions for search facets if needed
  &.search-facet__checkbox .mdc-checkbox {
    *, *::before {
      transition: none !important;
      animation: none !important;
    }
  }
}

.non-clickable {
  pointer-events: none;
}


.no-visibility {
  visibility: hidden;
}

.bulk-actions-access {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  height: 25px;
  display: flex;

  button {
    height: 40px;
    width: 40px !important;

    mat-icon {
      font-size: 22px;
      display: flex;
      justify-content: center;
      justify-self: center;
      align-items: center;
      align-self: center;
    }
  }
}

.not-clickable {
  pointer-events: none;
}

.restriction-cell-container {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 8px 0;

  mat-icon {
    font-size: 20px;
    width: 20px;
    height: 20px;
  }
}

