@use './variables' as vars;
@import './mixins.scss';

// Empty clipbin or search results
.empty-contents {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.15px;
  line-height: 24px;

  h1 {
    font-size: 20px;
    color: vars.$mam-white-60;
    margin-bottom: 4px;
    font-weight: 500;
  }

  h2 {
    font-size: 16px;
    color: vars.$mam-white-38;
    margin-top: 4px;
    font-weight: normal;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  .image-container {
    // For these sizes, note that the img has object-fit: contain so no skewing
    width: 444px;
    height: 228px;

    @include breakpoint-lg-max {
      width: 100%;
      height: 100px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.table-box {
  width: 100%;
  overflow-x: auto;
}

table {
  table-layout: fixed !important;
  display: table !important;

  &:hover {
    th {
      position: relative;
      user-select: none;

      &:not(:last-child) {
        .resizer-box {
          position: absolute;
          top: 0;
          right: -20px;
          z-index: 100;
          width: 40px;
          height: 100%;
          background-color: vars.$gmat-gray-900;
          cursor: col-resize;

          .resizer {
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(-50%, -50%);
            width: 3px;
            z-index: 100;
            height: 50%;
            background-color: vars.$mam-steel;
          }
        }
      }
    }
  }

}

.cdk-drag-preview {
  padding: 0 16px !important;
  display: grid;
  align-items: flex-end;
  box-sizing: border-box;
  border: none !important;
  border-radius: 4px;
  background-color: vars.$gmat-gray-900;
  box-shadow: 0 5px 5px -3px #00000033,
              0 8px 10px 1px #00000024,
              0 3px 14px 2px #0000001f;
}

.cdk-drag-animating {
  transition: transform .3s cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-placeholder {
  opacity: 0;
}

table.cdk-drop-list-dragging th:not(.cdk-drag-placeholder) {
  transition: transform .3s cubic-bezier(0, 0, 0.2, 1);
}
