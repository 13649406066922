@use '@angular/material' as mat;

$mam-red: #ff0008;
$mam-red-45: rgba(255, 0, 0, 0.45);

// Whites with n% of opacity
$mam-white-87: rgba(255, 255, 255, 0.87);
$mam-white-70: rgba(255, 255, 255, 0.7);
$mam-white-60: rgba(255, 255, 255, 0.6);
$mam-white-38: rgba(255, 255, 255, 0.38);
$mam-white-30: rgba(255, 255, 255, 0.3);
$mam-white-12: rgba(255, 255, 255, 0.12);
$mam-white-8: rgba(255, 255, 255, 0.08);
$mam-white-5: rgba(255, 255, 255, 0.05);
$mam-white-4: rgba(255, 255, 255, 0.04);

$mam-black: #121315;
$mam-steel: #3b3c3e;
$mam-black-80: rgba(0, 0, 0, 0.8);
$mam-black-76: rgba(0, 0, 0, 0.76);
$mam-black-38: rgba(0, 0, 0, 0.38);
$mam-black-20: rgba(0, 0, 0, 0.2);
$mam-black-5: rgba(0, 0, 0, 0.05);

// Shades of gray used in MAM
$gmat-gray-50:  #f8f9fa;
$gmat-gray-100: #f1f3f4;
$gmat-gray-200: #e8eaed;
$gmat-gray-300: #dadce0;
$gmat-gray-400: #bdc1c6;
$gmat-gray-500: #9aa0a6;
$gmat-gray-600: #80868b;
$gmat-gray-700: #5f6368;
$gmat-gray-800: #3c4043;
$gmat-gray-900: #202124;

// Primary palette
$gmat-gray: (
  50: $gmat-gray-50,
  100: $gmat-gray-100,
  200: $gmat-gray-200,
  300: $gmat-gray-300,
  400: $gmat-gray-400,
  500: $gmat-gray-500,
  600: $gmat-gray-600,
  700: $gmat-gray-700,
  800: $gmat-gray-800,
  900: $gmat-gray-900,
  A100: #ffffff,
  A200: #eeeeee,
  A400: #bdbdbd,
  A700: #616161,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: rgba(black, 0.87),
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: white,
  )
);

// Shades of green from gmat palette used in MAM
$gmat-green-600: mat.get-color-from-palette(mat.$green-palette, 600);
$gmat-green-400: mat.get-color-from-palette(mat.$green-palette, 400);

// Shades of red from gmat palette used in MAM
$gmat-red-300: mat.get-color-from-palette(mat.$red-palette, 300);
$gmat-red-400: mat.get-color-from-palette(mat.$red-palette, 400);
$gmat-red-500: mat.get-color-from-palette(mat.$red-palette, 500);
$gmat-red-700: mat.get-color-from-palette(mat.$red-palette, 700);

// Shades of yellow from gmat palette used in MAM
$gmat-yellow-300: mat.get-color-from-palette(mat.$yellow-palette, 300);

// Color for icons/icon buttons. E.g. table actions like next/prev, refresh, etc.
$mam-icon-color-gray-400: $gmat-gray-400;

// When transparent colors cannot be used.
$mam-opaque-black-5: #1e1f22;

// Used for asset date added for instance.
$mam-subtitle-gray: #7f868b;

// Used for flash asset (forward/rewind) over video.
$mam-flash-gray: #dadada;

// Used to mark label of the invalid form control.
$mam-error-orange: #fa7b17;

// Grid gaps for clip bin and search results.
$card-column-gap: 20px;
$card-row-gap: 25px;

// Dark gray border.
$mam-border-gray-800: 1px solid $gmat-gray-800;
// Border radius.
$mam-border-radius-4: 4px;

// Responsive width breakpoints.
$mam-facets-visible-min: 780px;
$xs-max: 360px;
$sm-min: 400px;
$md-min: 720px;
$lg-min: 960px;
$xl-min: 1200px;
$xxl-min: 1600px;

// Responsive height breakpoints.
$sm-max-height: 500px;


// Responsive breakpoints for landing page
$landing-sm-max: 540px;
$landing-lg-max: 1300px;

// Responsive breakpoint for large screen desktop computers
$desk-min: 1650px;

$mam-view-max-width: 2000px;

$small:600px;
$medium:960px;
$large:1280px;
$xlarge:1920px;
$web:840px;


$sm-max:839.98px;
$md-max:1279.98px;

// Under which window size will the panel be displayed below the content.
$mam-persistent-panel-breakpoint: 960px;

$collapsed-panel-width: 60px;

// Material Dark Theme Design Kit (from Figma)
$mat-dark-elevation-overlay-04dp: #272727;

// Colors used to highlight substrings in text (e.g. search on play_feed_panel)
$searchResultColor: black;
$searchResultBgColor: #fdd663;
$activeSearchResultBgColor: #fd8963;

// opacity for elements displayed on user action in full screen player mode
$full-screen-details-opacity: 0.8;

// toolbar elements
$mam-toolbar-upper-height: 76px;
$mam-toolbar-upper-height-mobile: 56px;
$mam-toolbar-lower-height: 72px;
$mam-toolbar-lower-height-mobile: 0;

// size of checkbox columns on ui table
$mam-table-checkbox-width: 46px;
$mam-table-checkbox-minwidth: 46px;

// table actions bar
$mam-table-actionbar-height: 48px;

// table tabs
$mam-table-tabs-height: 48px;
$mam-table-tabs-margin: 40px;
$mam-table-tabs-margin-mobile: 60px;

// predefined container heights
$mam-container-height-1: 24px;
$mam-container-height-2: 36px;
$mam-container-height-3: 40px;
$mam-container-height-4: 48px;
$mam-container-height-5: 52px;
