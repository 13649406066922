@use 'sass:math';
@use "sass:map";
@use './variables' as vars;

@mixin elevation($box-shadow) {
  &,
  &:not([class*='gmat-elevation-z']) {
    box-shadow: $box-shadow;
  }
}

@mixin ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// Generates a media query to define column count for the current element.
@mixin responsive-grid-query($min-width, $column-count) {
  @media (min-width: $min-width) {
    // Prevent grid blowout https://css-tricks.com/preventing-a-grid-blowout
    grid-template-columns: repeat($column-count, minmax(0, 1fr));
    @content;
  }
}

// Generates grid style and multiple media queries for the current element based
// on provided map of widths/column counts.
@mixin responsive-grid($width-column-breaks: ()) {
  display: grid;
  // Prevent grid blowout https://css-tricks.com/preventing-a-grid-blowout
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-gap: vars.$card-row-gap vars.$card-column-gap;

  @each $min-width, $column-count in $width-column-breaks {
    @include responsive-grid-query($min-width, $column-count);
  }
}

$breakpoints: (
  xs: vars.$xs-max,
  sm: vars.$sm-min,
  md: vars.$md-min,
  lg: vars.$lg-min,
  xl: vars.$xl-min,
  xxl: vars.$xxl-min,
);

$breakpoints-max-heights: (
  sm: vars.$sm-max-height,
);

/*
  $breakpoint: keyof $breakpoints
  $negate: boolean
*/
@mixin breakpoint($breakpoint, $negate: false) {
  $bp: map.get($breakpoints, $breakpoint);

  @if $negate {
    /**
     In the premiere pro CSS engine the "not" operator doesn't seem to be supported.
     We've introduced the min-width as the negation + 1px (to prevent overlap)
    */
    @media (min-width: #{$bp + 1px}) {
      @content;
    }
  } @else {
    @media (max-width: #{$bp}) {
      @content;
    }
  }
}

// Media query mixins
@mixin breakpoint-xs-min {
  @include breakpoint('xs', true) {
    @content;
  }
}

@mixin breakpoint-sm-min {
  @include breakpoint('sm', true) {
    @content;
  }
}

@mixin breakpoint-md-min {
  @include breakpoint('md', true) {
    @content;
  }
}

@mixin breakpoint-lg-min {
  @include breakpoint('lg', true) {
    @content;
  }
}

@mixin breakpoint-xl-min {
  @include breakpoint('xl', true) {
    @content;
  }
}

@mixin breakpoint-xxl-min {
  @include breakpoint('xxl', true) {
    @content;
  }
}

@mixin breakpoint-custom-max($screen) {
  @media (max-width: $screen) {
    @content;
  }
}

@mixin breakpoint-custom-min($screen) {
  @media (min-width: $screen) {
    @content;
  }
}

@mixin breakpoint-between($min, $max) {
  @media (min-width: $min) and (max-width: $max) {
    @content;
  }
}

// so far, $args consists of 1 argument, $negate

@mixin breakpoint-xs-max($args...) {
  @include breakpoint('xs', $args...) {
    @content;
  }
}

@mixin breakpoint-sm-max($args...) {
  @include breakpoint('sm', $args...) {
    @content;
  }
}

@mixin breakpoint-md-max($args...) {
  @include breakpoint('md', $args...) {
    @content;
  }
}

@mixin breakpoint-lg-max($args...) {
  @include breakpoint('lg', $args...) {
    @content;
  }
}

@mixin breakpoint-xl-max($args...) {
  @include breakpoint('xl', $args...) {
    @content;
  }
}

@mixin breakpoint-xxl-max($args...) {
  @include breakpoint('xxl', $args...) {
    @content;
  }
}

@mixin breakpoint-landing-sm-max {
  @media (max-width: #{vars.$landing-sm-max}) {
    @content;
  }
}

@mixin breakpoint-landing-lg-max {
  @media (max-width: #{vars.$landing-lg-max}) {
    @content;
  }
}

@mixin breakpoint-desk-max {
  @media (max-width: #{vars.$desk-min}) {
    @content;
  }
}

@mixin mouse-pointer {
  @media (pointer: fine) {
    @content;
  }
}

@mixin not-mouse-pointer {
  @media not (pointer: fine) {
    @content;
  }
}

@mixin touch-pointer {
  @media (pointer: coarse) {
    @content;
  }
}


@mixin breakpoint-max-height($breakpoint, $negate: false) {
  $bp: map.get($breakpoints-max-heights, $breakpoint);

  @if $negate {
   /**
     In the premiere pro CSS engine the "not" operator doesn't seem to be supported.
     We've introduced the min-width as the negation + 1px (to prevent overlap)
    */
    @media (min-height: #{$bp + 1px}) {
      @content;
    }
  } @else {
    @media (max-height: #{$bp}) {
      @content;
    }
  }
}

@mixin breakpoint-sm-max-height() {
  @include breakpoint-max-height('sm') {
    @content;
  }
}

@mixin breakpoint-sm-min-height {
  @include breakpoint-max-height('sm', true) {
    @content;
  }
}

// Grid Columns
@mixin grid-columns($cols) {
  grid-template-columns: repeat(#{$cols}, minmax(0, 1fr));
}
